<template>
  <v-container class="is-md py-12">
    <v-row align="end" class="mb-3">
      <v-col cols="12" md="8">
        <h3 class="text-h6 font-weight-bold">
          Manage username flairs
        </h3>
        <p class="text-caption mb-0">
          These are small icons added before a user's name throughout the site.
          Mostly used for denoting staff members or verified users.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-btn
          light color="primary--gradient" small :loading="loading.list"
          @click="fetchApiData"
        >
          <v-icon class="text-body-1 mr-1">
            mdi-sync
          </v-icon>
          Refresh
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="data-table mb-8"
      item-key="_id"
      :headers="headers"
      :items="entries"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
      :server-items-length="total"
      :expanded="expanded"
      :loading="loading.list"
      @pagination="onPagination"
    >
      <template #item.name="{ item, value }">
        <Username class="d-inline mr-2" :user="{name: value, flairs: [item]}" />
      </template>

      <template #item.createdBy="{ item }">
        <div class="d-flex align-center">
          <UserAvatar :user="item.creator" size="24" class="mr-2" />
          <Username :user="item.creator" no-flairs />
        </div>
      </template>

      <template #item.createdAt="{ value, item }">
        <v-tooltip top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
          </template>
          <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>

      <template #item.actions="{ item }">
        <v-icon small class="primary500--text mr-2 link" @click="onExpandItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon
          small class="primary500--text link"
          @click="onDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- transaction info -->
      <template #expanded-item="{ headers: head, item }">
        <td :colspan="head.length" class="grey800 py-3 data-detail">
          <FlairForm
            :value="item"
            :loading="loading.create"
            @submit="onSetFlair"
          />
        </td>
      </template>
    </v-data-table>

    <!-- <v-card class="pa-4 mb-4" color="grey700">
      imagine
    </v-card> -->

    <v-card class="pa-4" color="grey800">
      <v-container class="is-sm">
        <v-card class="pa-4" color="grey700">
          <h4 class="text-body-1 primary500--text mb-4 font-weight-bold">
            Create new flair
          </h4>

          <FlairForm
            v-model="newFlairForm"
            :loading="loading.create"
            is-new
            @submit="onSetFlair"
          />
        </v-card>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import FlairForm from './components/FlairForm'

const newFlairFormDefault = () => ({
  name: null,
  color: null,
  icon: null,
})

export default {
  metaInfo: {
    title: 'User flairs',
  },
  components: {
    FlairForm,
  },
  data() {
    return {
      loading: {
        list: false,
        create: false,
      },
      total: 0,
      expanded: [],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Flair', value: 'name', align: 'start' },
        { text: 'Last updated by', value: 'createdBy' },
        {
          text: 'Created', value: 'createdAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      entries: [],
      newFlairForm: newFlairFormDefault(),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
  },
  methods: {
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item._id === expandedItem?._id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    async fetchApiData() {
      this.loading.list = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.flairs.list', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onSetFlair(form) {
      this.loading.create = true

      try {
        const flair = await this.$socket.request('admin.flairs.set', form)

        this.newFlairForm = newFlairFormDefault()
        this.$toast.success(`User flair "${flair.name}" set successfully.`)

        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onDelete({ _id }) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.flairs.delete', _id)

        this.$toast.success(`User flair "${_id}" deleted successfully.`)
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
