<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="8">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Flair name</label>
          <v-text-field
            v-model="form.name" filled dense color="primary500"
            placeholder="Shown on hover"
            hide-details
          />
        </fieldset>
      </v-col>

      <v-col cols="12" md="4">
        <fieldset>
          <label class="text-body-2 d-block mb-1">Name color</label>
          <v-menu
            v-model="colorPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="form.color"
                filled dense color="primary500"
                placeholder="no change"
                hide-details
              >
                <template #prepend-inner>
                  <v-icon :color="form.color" class="text-body-1 mt-1">
                    mdi-circle
                  </v-icon>
                </template>
                <template #append>
                  <v-icon class="primary500--text link" v-on="on">
                    mdi-palette
                  </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-color-picker
              mode="rgba"
              :value="form.color"
              @update:color="onColorInput"
            />
          </v-menu>
        </fieldset>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="3">
            <v-hover>
              <template #default="{hover}">
                <v-img class="grey400" :src="form.icon" :aspect-ratio="1" contain>
                  <v-fade-transition>
                    <div v-if="!form.icon || hover" class="full-height item-variant-icon">
                      <v-btn
                        v-if="form.icon"
                        icon small absolute top
                        right
                        @click="form.icon = null"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>

                      <FileDropArea
                        :files="files"
                        :loading="uploadingFile"
                        disable-hint
                        disable-label
                        accept="image/*"
                        class="rounded-0 full-height"
                        @submit="onImageSubmitted"
                        @rejectedFiles="$toast.error('File type invalid')"
                      />
                    </div>
                  </v-fade-transition>
                </v-img>
              </template>
            </v-hover>
          </v-col>
          <v-col cols="12" md="9">
            <fieldset>
              <label class="text-body-2 d-block">Item icon url</label>
              <p class="text-caption mb-2">
                Please upload images around 64x64 pixels
              </p>
              <v-text-field
                :value="form.icon" filled dense color="primary500"
                placeholder="Icon url (read only)" readonly
                hide-details
              />
            </fieldset>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <span class="text--secondary">Flair preview: </span>
        <Username
          class="d-inline font-weight-medium"
          :user="{
            name: 'robloxkiller2004',
            flairs: [{ ...form, }]}
          "
        />
      </v-col>

      <v-col cols="12" class="text-right">
        <v-btn
          light class="font-weight-bold"
          color="green--gradient"
          :loading="loading"
          @click="onSubmit"
        >
          <v-icon small class="mr-1">
            mdi-check-bold
          </v-icon>
          {{ isNew ? 'Create' : 'Update' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from 'axios'
import FileDropArea from '@/components/FileDropArea'

export default {
  components: { FileDropArea },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uploadingFile: false,
      form: null,
      colorPicker: false,
      files: [],
    }
  },
  watch: {
    value: {
      handler(val) {
        if (JSON.stringify(val) === JSON.stringify(this.form)) return
        this.form = { ...val }
      },
      immediate: true,
      deep: true,
    },
    form: {
      handler(val) {
        this.$emit('input', { ...val })
      },
      deep: true,
    },
    files(files) {
      if (files.length) {
        this.files = []
      }
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
    async onImageSubmitted(files) {
      if (this.uploadingFile) return

      const [file] = files
      if (!file) return

      this.uploadingFile = true

      try {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('metadata', JSON.stringify({ kind: 'flair-icon' }))

        const { data: { response: resp } } = await axios.post(
          `${CONFIG.api.url}/acp/items/upload-image`,
          fd,
          {
            withCredentials: true,
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        )

        const url = resp.variants.find(i => i.includes('/icon'))

        this.form.icon = url
      } catch (error) {
        let err = error

        if (error.isAxiosError) {
          err = err.response.data.response
        }

        this.$toast.error(err.message)
      } finally {
        this.uploadingFile = false
      }
    },
    onColorInput(val) {
      if (!this.colorPicker) return

      this.form.color = val.alpha < 1 ? val.hexa : val.hex
    },
  },
}
</script>
