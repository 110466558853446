var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"is-md py-12"},[_c('v-row',{staticClass:"mb-3",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Manage username flairs ")]),_c('p',{staticClass:"text-caption mb-0"},[_vm._v(" These are small icons added before a user's name throughout the site. Mostly used for denoting staff members or verified users. ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"light":"","color":"primary--gradient","small":"","loading":_vm.loading.list},on:{"click":_vm.fetchApiData}},[_c('v-icon',{staticClass:"text-body-1 mr-1"},[_vm._v(" mdi-sync ")]),_vm._v(" Refresh ")],1)],1)],1),_c('v-data-table',{staticClass:"data-table mb-8",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 25] },"server-items-length":_vm.total,"expanded":_vm.expanded,"loading":_vm.loading.list},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('Username',{staticClass:"d-inline mr-2",attrs:{"user":{name: value, flairs: [item]}}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('UserAvatar',{staticClass:"mr-2",attrs:{"user":item.creator,"size":"24"}}),_c('Username',{attrs:{"user":item.creator,"no-flairs":""}})],1)]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(new Date(value).getTime(),'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(new Date(value).getTime(),'YYYY-MM-DD HH:mm:ss')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"primary500--text mr-2 link",attrs:{"small":""},on:{"click":function($event){return _vm.onExpandItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"primary500--text link",attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"grey800 py-3 data-detail",attrs:{"colspan":head.length}},[_c('FlairForm',{attrs:{"value":item,"loading":_vm.loading.create},on:{"submit":_vm.onSetFlair}})],1)]}}])}),_c('v-card',{staticClass:"pa-4",attrs:{"color":"grey800"}},[_c('v-container',{staticClass:"is-sm"},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"grey700"}},[_c('h4',{staticClass:"text-body-1 primary500--text mb-4 font-weight-bold"},[_vm._v(" Create new flair ")]),_c('FlairForm',{attrs:{"loading":_vm.loading.create,"is-new":""},on:{"submit":_vm.onSetFlair},model:{value:(_vm.newFlairForm),callback:function ($$v) {_vm.newFlairForm=$$v},expression:"newFlairForm"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }